var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "py-2" },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { justify: "end" } },
            [
              _c("LanguageSelection", {
                class: _vm.$vuetify.breakpoint.lg ? "mb-3" : ""
              })
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "my-0 py-0" },
                        [
                          _c("v-img", {
                            staticClass: "ma-4",
                            attrs: {
                              "max-width": "150px",
                              src: require("@/assets/images/VitrueVidaLogoWithText.png")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "my-0 py-0",
                      attrs: { justify: "center", "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8", md: "6", lg: "12" } },
                        [
                          _c("v-img", {
                            staticClass: "ma-4",
                            attrs: {
                              "max-width": _vm.isDeskAssessment
                                ? "450px"
                                : "300px",
                              contain: "",
                              src: _vm.isDeskAssessment
                                ? require("@/assets/images/VitrueVidaDeskImage.png")
                                : require("@/assets/images/burnoutassessment/burnoutWelcome.png")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-h4 primary--text",
                      class: _vm.$vuetify.breakpoint.mdAndDown
                        ? "text-center"
                        : ""
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.displayText.title)) + " ")]
                  ),
                  _c(
                    "p",
                    {
                      class: _vm.$vuetify.breakpoint.mdAndDown
                        ? "text-center"
                        : ""
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t(_vm.displayText.subtitle)) + " "
                      )
                    ]
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c("v-img", {
                        staticClass: "ma-5",
                        attrs: {
                          "max-width": "25px",
                          src: require("@/assets/images/emojis/pageWithCurl.png")
                        }
                      }),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-wrap" },
                            [_vm._v(_vm._s(_vm.$t(_vm.displayText.firstStep)))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c("v-img", {
                        staticClass: "ma-5",
                        attrs: {
                          "max-width": "25px",
                          src: require("@/assets/images/emojis/horizontalTrafficLight.png")
                        }
                      }),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-wrap" },
                            [_vm._v(_vm._s(_vm.$t(_vm.displayText.secondStep)))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c("v-img", {
                        staticClass: "ma-5",
                        attrs: {
                          "max-width": "25px",
                          src: require("@/assets/images/emojis/blush.png")
                        }
                      }),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-wrap" },
                            [_vm._v(_vm._s(_vm.$t(_vm.displayText.thirdStep)))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column mt-8" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "max-width": "200px",
                        color: "primary",
                        "data-cy": "start"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.getStarted")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-row", { staticClass: "ma-0 pa-0", attrs: { justify: "center" } }, [
        _c("p", {
          staticClass: "text-caption font-weight-light",
          domProps: {
            innerHTML: _vm._s(_vm.$t("welcomeToAssessment.termsOfService"))
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }