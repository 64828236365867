<template>
  <v-card class="py-2">
    <v-card-text>
      <v-row justify="end">
        <LanguageSelection :class="$vuetify.breakpoint.lg ? 'mb-3' : ''" />
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="6">
          <v-row align="center">
            <v-col class="my-0 py-0"
              ><v-img
                class="ma-4"
                max-width="150px"
                src="@/assets/images/VitrueVidaLogoWithText.png"
              ></v-img
            ></v-col>
          </v-row>
          <v-row justify="center" class="my-0 py-0" no-gutters>
            <v-col cols="8" md="6" lg="12"
              ><v-img
                class="ma-4"
                :max-width="isDeskAssessment ? '450px' : '300px'"
                contain
                :src="
                  isDeskAssessment
                    ? require('@/assets/images/VitrueVidaDeskImage.png')
                    : require('@/assets/images/burnoutassessment/burnoutWelcome.png')
                "
              ></v-img
            ></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6">
          <p
            class="text-h4 primary--text"
            :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : ''"
          >
            {{ $t(displayText.title) }}
          </p>
          <p :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : ''">
            {{ $t(displayText.subtitle) }}
          </p>
          <v-list-item>
            <v-img
              max-width="25px"
              class="ma-5"
              src="@/assets/images/emojis/pageWithCurl.png"
            ></v-img>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                $t(displayText.firstStep)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-img
              max-width="25px"
              class="ma-5"
              src="@/assets/images/emojis/horizontalTrafficLight.png"
            ></v-img>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                $t(displayText.secondStep)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-img
              max-width="25px"
              class="ma-5"
              src="@/assets/images/emojis/blush.png"
            ></v-img>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                $t(displayText.thirdStep)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <div class="d-flex flex-column mt-8">
          <v-btn
            max-width="200px"
            color="primary"
            @click="closeDialog()"
            data-cy="start"
            >{{ $t("buttons.getStarted") }}</v-btn
          >
        </div>
      </v-row>
    </v-card-text>
    <v-row justify="center" class="ma-0 pa-0">
      <p
        class="text-caption font-weight-light"
        v-html="$t('welcomeToAssessment.termsOfService')"
      ></p
    ></v-row>
  </v-card>
</template>

<script>
import WelcomeText from "@/assets/json/common/WelcomeText.json";
import LanguageSelection from "@/components/common/LanguageSelection.vue";
import LanguageService from "@/services/language-service.js";
export default {
  name: "WelcomeDialog",
  props: {
    assessmentType: String
  },
  components: {
    LanguageSelection
  },
  data() {
    return {
      toggle_exclusive: this.returnButtonNumber()
    };
  },
  computed: {
    displayText() {
      return this.isDeskAssessment
        ? WelcomeText.deskAssessment
        : WelcomeText.burnout;
    },
    isDeskAssessment() {
      return this.assessmentType === "DeskAssessment";
    }
  },
  methods: {
    closeDialog() {
      this.open = false;
      this.$emit("input", false);
    },
    updateLanguage(language) {
      LanguageService.setLocaleValue(language);
    },
    returnButtonNumber() {
      switch (this._i18n.locale) {
        case "en-gb":
          return 0;
        case "en-us":
          return 1;
        case "es":
          return 2;
        case "es-mx":
          return 3;
        default:
          return 0;
      }
    }
  }
};
</script>
