var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-row", { attrs: { align: "center" } }, [
    _c(
      "div",
      { staticClass: "d-flex justify-start align-center" },
      [
        _c("v-img", {
          attrs: {
            src: require("@/assets/images/" + _vm.imageUrl),
            width: "80px",
            height: "80px",
            contain: ""
          }
        }),
        _c("div", { staticClass: "speech-bubble ml-5" }, [
          _c("p", {
            staticClass: "text-body-2 text--secondary ma-0 pa-3",
            domProps: { innerHTML: _vm._s(_vm.text) }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }