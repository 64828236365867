<template>
  <v-stepper v-model="currentThemeIndex" alt-labels class="elevation-0" tile>
    <v-stepper-header
      class="elevation-0"
      style="margin-left: -50px; margin-right: -50px"
    >
      <template v-for="(title, index) in titles">
        <v-stepper-step
          :key="`${index}-step`"
          :complete="currentThemeIndex > index + 1"
          :step="index + 1"
          :color="currentThemeIndex > index + 1 ? 'secondary' : 'primary'"
          class="text-caption pb-2"
        >
          <p
            style="word-wrap: break-word"
            :style="
              $vuetify.breakpoint.mdAndDown
                ? 'font-size: 10px; max-width: 60px'
                : ' max-width: 70px'
            "
            class="text-center"
            :class="
              index + 1 == currentThemeIndex ? 'black--text' : 'disabled--text'
            "
          >
            {{ $t(title) }}
          </p>
        </v-stepper-step>
        <v-divider :key="index" v-if="index < titles.length - 1" />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: "stepper",
  props: {
    schema: Array[Object],
    currentTheme: String
  },
  computed: {
    currentThemeIndex() {
      return this.themes.indexOf(this.currentTheme) + 1;
    },
    titles() {
      return this.schema.map(x => x.title);
    },
    themes() {
      return this.schema.map(x => x.theme);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-stepper__label {
  display: block !important;
}
</style>
