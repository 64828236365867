<template>
  <v-row align="center">
    <div class="d-flex justify-start align-center">
      <v-img
        :src="require('@/assets/images/' + imageUrl)"
        width="80px"
        height="80px"
        contain
      />
      <div class="speech-bubble ml-5">
        <p class="text-body-2 text--secondary ma-0 pa-3" v-html="text" />
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "speech-bubble",
  props: {
    text: String,
    imageUrl: String
  }
};
</script>

<style scoped>
.speech-bubble {
  position: relative;
  background: #e8e8e8;
  border-radius: 1em;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;

  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 18px solid #e8e8e8;
  margin-top: -10px;
  margin-left: -17px;
}
</style>
