import router from "@/router";
import sentry from "@/sentry";
import mixpanel from "mixpanel-browser";
import * as Constants from "@/constants/constants.js";
import { updateAssessmentState, getUserAssessmentState } from "@/customApi";
import { getAssessmentResult } from "@/services/modules/api-queries";
import store from "@/store";
import i18n from "@/plugins/i18n.js";

export default {
  async getAssessmentStateAndResults(
    assessmentId,
    assessmentType,
    allowSettingInProgress
  ) {
    var succeeded = false;
    var currentAttempts = 0;
    var cancelLoop = false;
    var assessmentStarted = false;
    var loadedResults = null;
    var redirectObject = null;

    store.commit("addAsyncRequest");
    while (currentAttempts < 3 && succeeded === false && cancelLoop === false) {
      try {
        currentAttempts++;
        redirectObject = null;

        // get user assessment state information
        let userAssessmentState = await getUserAssessmentState(assessmentId);
        if (!userAssessmentState) {
          throw new Error("Assessment state not found");
        }

        // handle sso information
        let idp = userAssessmentState.idp;
        let teamId = userAssessmentState.teamId;
        if (idp) {
          sessionStorage.setItem("idpName", idp);
          store.commit("setTeamIdpName", idp);
        }
        if (teamId) {
          sessionStorage.setItem("teamId", teamId);
          store.commit("setTeamId", teamId);
        }

        // set users email from assessment
        let email = userAssessmentState.email;
        if (!email) {
          sentry.captureMessage(
            `Failed to get an email for ${assessmentType}`,
            {
              assessmentId: assessmentId,
              level: "error"
            }
          );
        } else {
          store.commit("setUserEmail", email);
        }

        // set feature flags
        if (userAssessmentState.customerId) {
          store.dispatch("setFeatureFlags", {
            customerId: userAssessmentState.customerId,
            features: userAssessmentState.features
          });
        }

        // If assessment isn't started, set to in progress and exit loop
        assessmentStarted = userAssessmentState.isAssessmentStarted;
        if (!assessmentStarted) {
          if (allowSettingInProgress) {
            await this.setAssessmentToInProgress(assessmentId, assessmentType);
          }
          succeeded = true;
          break;
        }

        // if not signed in and assessment has been started previously - direct user to sign in / sign up based on state
        if (!store.getters.signedIn) {
          let redirectAssessment = {
            assessmentType: assessmentType,
            id: assessmentId,
            results: {}
          };

          sessionStorage.setItem(
            "reportAssessment",
            JSON.stringify(redirectAssessment)
          );

          if (userAssessmentState.hasUserSignedUp || idp) {
            redirectObject = {
              name: "Login",
              params: {
                errorMsg:
                  assessmentType === Constants.assessmentTypes.deskAssessment
                    ? i18n.t("errorMessages.deskAssessmentStartedWithAccount")
                    : i18n.t(
                        "errorMessages.burnoutAssessmentStartedWithAccount"
                      ),
                isReturningToPartialAssessment: true
              }
            };
          } else {
            store.commit("setRedirectAssessment", redirectAssessment);
            redirectObject = {
              name: "SignUp",
              params: {
                assessmentId: assessmentId,
                errorMsg:
                  assessmentType === Constants.assessmentTypes.deskAssessment
                    ? i18n.t("errorMessages.deskAssessmentStartedNoAccount")
                    : i18n.t("errorMessages.burnoutAssessmentStartedNoAccount"),
                isReturningToPartialAssessment: true
              }
            };
          }
          succeeded = true;
          break;
        }

        // load results
        loadedResults = await getAssessmentResult(assessmentId, assessmentType);

        // force state change in hotjar to update page
        if (store.getters.signedIn && loadedResults.isCompleted) {
          if (Constants.assessmentTypes.deskAssessment) {
            hj("stateChange", `${router.currentRoute.path}/deskreport`);
          } else {
            hj("stateChange", `${router.currentRoute.path}/burnoutreport`);
          }
        }

        succeeded = true;
      } catch (e) {
        sentry.captureException(e);
        sentry.captureMessage(
          `Retrieving results in assessment failed, attempt number = ${currentAttempts}`,
          "error"
        );

        redirectObject = CreateRedirectObjectOnException(e);
        if (
          e.message &&
          (e.message.includes("AssessmentNotFound") ||
            e.message.includes("UnauthorizedAssessmentAccess"))
        ) {
          cancelLoop = true;
        }
      }
    }
    store.commit("removeAsyncRequest");

    return {
      assessmentStarted: assessmentStarted,
      results: loadedResults,
      redirectObject: redirectObject
    };
  },
  async setAssessmentToInProgress(assessmentId, assessmentType) {
    try {
      mixpanel.track(`${assessmentType} started`);
      await updateAssessmentState(assessmentId, "InProgress");
    } catch (err) {
      sentry.captureException(err);
    }
  }
};

function CreateRedirectObjectOnException(exception) {
  let redirectObject = { name: "ErrorPage" };
  if (exception.message?.includes("AssessmentNotFound")) {
    redirectObject.params = {
      showTitle: false,
      message: i18n.t("deskAssessment.assessmentNotAvailableText"),
      tryAgain: false
    };
  } else if (exception.message?.includes("UnauthorizedAssessmentAccess")) {
    redirectObject.params = {
      showTitle: false,
      message: i18n.t("deskAssessment.assessmentBelongsToOtherUser"),
      tryAgain: false
    };
  } else {
    redirectObject.params = {
      showTitle: true,
      message: i18n.t("deskAssessment.technicalErrorLoadingAssessment"),
      tryAgain: true
    };
  }
  return redirectObject;
}
