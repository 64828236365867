var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      staticClass: "elevation-0",
      attrs: { "alt-labels": "", tile: "" },
      model: {
        value: _vm.currentThemeIndex,
        callback: function($$v) {
          _vm.currentThemeIndex = $$v
        },
        expression: "currentThemeIndex"
      }
    },
    [
      _c(
        "v-stepper-header",
        {
          staticClass: "elevation-0",
          staticStyle: { "margin-left": "-50px", "margin-right": "-50px" }
        },
        [
          _vm._l(_vm.titles, function(title, index) {
            return [
              _c(
                "v-stepper-step",
                {
                  key: index + "-step",
                  staticClass: "text-caption pb-2",
                  attrs: {
                    complete: _vm.currentThemeIndex > index + 1,
                    step: index + 1,
                    color:
                      _vm.currentThemeIndex > index + 1
                        ? "secondary"
                        : "primary"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-center",
                      class:
                        index + 1 == _vm.currentThemeIndex
                          ? "black--text"
                          : "disabled--text",
                      staticStyle: { "word-wrap": "break-word" },
                      style: _vm.$vuetify.breakpoint.mdAndDown
                        ? "font-size: 10px; max-width: 60px"
                        : " max-width: 70px"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(title)) + " ")]
                  )
                ]
              ),
              index < _vm.titles.length - 1
                ? _c("v-divider", { key: index })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }